<template>
  <div class="zengpinlist">
    <van-search
      v-model="keywords"
      show-action
      placeholder="请输入搜索关键词"
      @search="onSearch"
      shape="round"
    >
      <template #left>
        <van-icon @click="backurl" name="arrow-left" />
      </template>
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <div class="main" v-if="!nodata">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :immediate-check="false"
        @load="getList"
      >
        <van-cell-group v-for="(item, index) in list" :key="index" :border="false">
          <van-cell class="chognzhi_list" :border="false" :to="{path: item.is_lingqu == 1 ? '' : 'zengpinDetail?id='+item.id+'&rechageId='+item.rechageId}">
            <template slot="title">充值单号：{{item.logno}}</template>
            <template slot="right-icon">&yen;{{item.recharemoney}}</template>
          </van-cell>
          <van-cell center :to="{path: item.is_lingqu == 1 ? '' : 'zengpinDetail?id='+item.id+'&rechageId='+item.rechageId}">
            <template slot="title">
              参与活动：{{item.title}}
              <span :class="item.is_lingqu == 1 ? 'red' : 'green'">{{item.is_lingqu == 1 ? '已领取' : '未领取'}}</span>
            </template>
            <template slot="right-icon">
              <div class="right_icon" v-if="item.is_lingqu == 1 ? true : true">
                去领取<van-icon name="arrow" />
              </div>
            </template>
          </van-cell>
        </van-cell-group>
      </van-list>
    </div>
    <div class="mian" v-if="nodata">
      <van-empty description="暂无活动" />
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant';
export default {
  name: "ZengPingList",
  data () {
    return {
      list: [],
      keywords: "",
      size: 20,
      offset: 0,
      nodata: false,
      loading: false,
      finished: false,
      shareid: 0,
      merchid: 0,
    }
  },
  mounted() {
    console.log("ddd");
  },
  activated() {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.query.shareid) {
        this.shareid = this.$route.query.shareid
      }else if (localStorage.getItem("DK_MEMBERID")) {
        this.shareid = localStorage.getItem("DK_MEMBERID");
      }
      this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
      this.$parent.getmerchname(this.merchid, "领取赠品");
      this.onSearch();
    },
    onSearch() {
      this.offset = 0;
      this.list = [];
      this.getList();
    },
    // 获取列表
    getList() {
      let _that = null;
      _that = this;
      if (_that.offset == 0) {
        Toast({
          type: 'loading',
          message: "加载中...",
          overlay: true,
          forbidClick: true,
          duration: 0
        })
      }
      _that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
      _that.$axios.defaults.headers["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(
          _that.$store.state.domain + "web/recharge/getList",
          _that.$qs.stringify({
            size: _that.size,
            offset: _that.offset,
            keywords: _that.keywords,
          })
        )
        .then(res => {
          _that.nodata = false;
          _that.loading = false;
          _that.finished = false;
          if(_that.offset == 0) {
            Toast.clear();
          }
          
          if (res.data.code == 100000) {
            if (_that.offset == 0) {
              _that.list = res.data.data;
            }else {
              res.data.data.forEach(item => {
                _that.list.push(item);
              })
            }
            if(res.data.data.length < _that.size) {
              _that.finished = true;
            }
            _that.offset += res.data.data.length;
          }else {
            if (_that.offset == 0) {
              _that.nodata = true;
            }else {
              _that.finished = true;
            }
          }
          
          console.log(_that.list);
        })
        .catch(err => {
          console(err)
          Toast.clear();
        })
    },
    // 返回上一页
    backurl() {
      this.$router.back();
    },
  },
}
</script>
<style lang="less">
.zengpinlist {
  overflow: hidden;
  bakcground-color: #ededed;

  .van-search {
    padding-left: 0;
    .van-icon-arrow-left {
      padding: 0 16px;
      line-height: 34px;
    }
    .van-search__action {
      padding: 0 16px;
    }
  }
  .van-cell__title {
    flex: 1;
    text-align: left;
  }
  .red {
    color: #EE0A24;
  }
  .green {
    color: #07C160;
  }

  .chognzhi_list {
    padding-bottom: 0;
  }
  .right_icon {
    display: flex;
    align-content: center;
    align-items: center;
  }
}
</style>